import * as React from "react";
import { Link } from "gatsby";
import stickerCacao from "../assets/images/sticker-2.png";

const pageStyles = {
  color: "#3e2a47", // Un color que combine con el chocolate
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
};

const containerStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "40px",
  flexWrap: "wrap", // Para hacer la disposición más flexible en pantallas pequeñas
};

const textColumnStyles = {
  maxWidth: "600px",
};

const headingStyles = {
  marginTop: 0,
  marginBottom: 48,
  fontSize: "2.5rem",
  color: "#6f4f1e", // Un color cálido como el chocolate
};

const paragraphStyles = {
  marginBottom: 48,
  fontSize: "1.2rem",
  lineHeight: "1.6",
  color: "#4e2a0f", // Color oscuro para texto
};

const imgStyles = {
  maxWidth: "400px", // Ajusta el tamaño de la imagen
};

const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <h1 style={headingStyles}>¡Oh no! No encontramos lo que buscas 😢🍫</h1>
      <div style={containerStyles}>
        <div style={textColumnStyles}>
          <p style={paragraphStyles}>
            Parece que esta página se ha derretido como un trozo de chocolate en
            un día caluroso ☀️. Pero no te preocupes, ¡el 13° Festival del
            Chocolate de Tabasco está aquí para salvar el día! 🍫🎉
          </p>
          <p style={paragraphStyles}>
            Si prefieres regresar a la página principal y disfrutar de más dulce
            contenido, <Link to="/">¡haz clic aquí para volver a casa!</Link>
          </p>
          <p style={{ fontSize: "1.5rem", color: "#6f4f1e" }}>
            ¡Nos vemos en el 13° Festival del Chocolate! 🍫🎉
          </p>
        </div>
        <div>
          <img
            src={stickerCacao} // URL de la imagen
            alt="Festival del Chocolate Tabasco"
            style={imgStyles}
          />
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;

export const Head = () => (
  <title>¡Página no encontrada! | 13° Festival del Chocolate Tabasco</title>
);
